import React, { useMemo } from "react";
import Layout from "../../components/Layout";
import { Container } from "react-bootstrap";
import { graphql, PageProps } from "gatsby";
import ProductCategorySearch from "../../components/ProductCategorySearch";
import { LandingSectionsInterface, LocalizedContextInterface, Seo } from "../../types/SanityTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import BlockRenderer from "../../components/BlockRenderer";
import { dictionaryParser } from "../../utils/dictionaryParser";
// import SearchInput from "../../components/Header/searchInput";

type SearchPageContext = LocalizedContextInterface;

type SearchPageProps = {
  search: {
    seo: Seo;
    slug: {
      current: string;
    };
    name: string;
    heading: string;
    landingSections: LandingSectionsInterface[];
  };
} & LocalizedContextInterface;

export const data = graphql`
  query SearchPage($_id: String, $language: String) {
    search: sanitySearch(_id: { eq: $_id }) {
      ...SearchFieldsFull
    }
    sanityDictionary {
      translations {
        key
        translation
      }
    }
    ...LocalizedContext
  }
`;

const SearchPage = (props: PageProps<SearchPageProps, SearchPageContext>) => {
  const initData = {
    hits: []
  };
  const { htmlLang, siteName } = useSiteMetadata(props.pageContext.language);
  const searchPage = props.data.search;

  const dictionaryList = props.data.sanityDictionary.translations;
  const dictionary = useMemo(() => dictionaryParser(dictionaryList), [dictionaryList]);

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName="search"
      pageType={"products"}
      analyticsTagMeta={{ promoId: "7" }}
    >
      <StructuredData
        type={"Search"}
        name={siteName}
        description={searchPage?.seo.metaDescription}
        slug={props.path}
        language={htmlLang}
      />
      <SEO title={searchPage?.seo.metaTitle} description={searchPage?.seo.metaDescription} />
      <Container fluid className="search-page">
        {/* <SearchInput dictionary={dictionary} isSearchPage={true} /> */}

        {/* <ProductCategorySearch
          categoryPageId={""}
          sanitySiteSettings={props.pageContext?.sanitySiteSettings}
          productLabels={props.data?.sanityLabels?.productLabels}
          searchLabels={props.data?.sanityLabels?.searchLabels}
          initData={initData}
          isCategory={false}
        /> */}
      </Container>
      {searchPage?.landingSections && (
        <>
          {searchPage.landingSections.map((section, index) => (
            <BlockRenderer key={"landingSection" + index} index={index} section={section} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default SearchPage;
